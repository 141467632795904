@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.button {
		background-color: #004D40;
		border-radius: 5px;
		color: white;
		padding: 10px 20px;
		border: 2px solid #004D40;
	}
	.button-white {
		background-color: white;
		border-radius: 5px;
		color: #004D40;
		padding: 10px 20px;
		font-weight: 500;
		border: 2px solid #004D40;
	}
	.text-input {
		background-color: white;
		border: none;
		margin-bottom: 10px;
		height: 2rem;
		filter: drop-shadow(0px 3px 3px black);
	}
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0px;
	font-size: 100%;
	vertical-align: baseline;
  font-family: 'Lato', sans-serif;
	box-sizing: border-box;
	color: #263238;
}

#deleteColumn, #expired-photo {
	background-color: white;
	color: #263238;
	z-index: 100;
	filter: 
		drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) 
		drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}

/* width and height */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 4px;
	margin-left: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.interaction-index, .card-column, .board {
	scrollbar-gutter: stable;
}

.toast {
	background-color: #004D40;
}

.Collapsible {
  background-color: #cfd8dc;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 5px;
  width: 100%;
}
.Collapsible > span {
  text-align: center;
  display: block;
}



/* email button tooltip */
#email-button {
	background-color: white;
	color: #263238;
	z-index: 100;
	max-width: 310px;
	padding: 3px 10px;
	opacity: 100;
	filter: 
		drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) 
		drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}